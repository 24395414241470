.optionEmpahsis {
    font-style: italic;
}

.optionNameSection {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 24px;
}

.editableOptionNameArea {
    position: relative;
}

.editTitleForm {
    width: 100%;
    margin: 14px 0px 16px 0px;
}

.titleEditButton {
    margin: 0px 8px 0px 0px;
    display: inline-block;
}

.editTitleInput {
    width: 100%;
    top: 14px;
    margin-bottom: 8px;
}

.inputRow0 .dropdownWrapper {
    width: calc(40% + 16px) !important;
    margin-right: 0;
}