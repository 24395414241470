.inputRow0 .removeButton {
    display: none !important;
}



*, body {
    box-sizing: border-box;
    padding: 0px;
  }

  body {
    background: #fffffe;
    margin: 0 !important;
  }
  
  .App {
    text-align: left;
    width: 100%;
  }

  .mainContent {
    padding-bottom: 2rem;
    padding-top: 1rem;
    margin-top: 0 !important;
  }

  .color_primary {
    color: #4091c9;
  }

  .background_primary {
    background: #4091c9;
    color: #fff;
  }

  .background_white {
    background: #fff;
    color: #094067;
    border-bottom: 1px solid #e6e6e6;
  }

  .background_lightGrey {
    background: #fdfdfd;
    border-top: 1px solid #e6e6e6;
    color: #4d4d4d;
  }

  .color_primary__light {
    color: #9dcee2;
  }

  .color_primary__dark {
    color: #1368aa;
  }

  .color_primary__darker {
    color: #094067;
  }

  .color_secondary {
    color: #f26a4f;
  }

  .color_secondary__light {
    color: #f29479;
  }

  .color_secondary__lighter {
    color: #fedfd4;
  }

  .color_secondary__dark {
    color: #ef3c2d;
  }

  .color_secondary__darker {
    color: #cb1b16;
  }

  .color_secondary__darkest {
    color: #65010c;
  }

  .color_tertiary {
    color: #90b4ce;
  }

  .color_tertiary__dark {
    color: #5f6c7b;
  }



  h1, h2, h3, h4, h5, h6, p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-weight: 500;
      color: #094067;
  }

  h1 {
      font-size: 1.75rem;
      margin-top: 0;
  }

  h2 {
    margin-top: 0px;
}

h3 {
    margin: 0 0 1.5rem 0;
}

p {
    color: #5f6c7b;
    font-weight: 300;
    line-height: 1.5rem;
}

ul, ol {
    padding-left: 1rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    line-height: 1.5;
}

li {
    font-weight: 300;
    color: #5f6c7b;
    line-height: 1.5;
    margin: 0.5rem 0;
}

footer li {
    margin: 0;
    font-weight: 300;
}

a {
    color: #094067;
    transition: all 0.3s;
}

a:hover {
    color: #3E6D8E;
    text-decoration: none;
}

.optionName {
    margin-right: 1rem;
    line-height: 2rem;
    display: inline-block;
}

.optionInstructions {
    margin-bottom: 0;
    margin-top: 8px;
}

.caption {
    display: block;
    font-weight: 400;
    font-size: 0.8rem;
    color: #808080;
    margin-bottom: 0;
    margin-top: 0;
}
  
  .main-section {
    display: flex;
    flex-direction: column;
    padding: 20px 32px;
    flex: 1 70%;
  }

  .intro {
      margin-bottom: 1.5rem;
  }

  .intro p {
      margin-bottom: 0;
  }
.optionsListSection {
    margin-top: 1rem;
}
  .formSectionContainer {
      padding: 0px;
      margin-top: 1.5rem;
  }

  .optionContainer {
    background: #fff;
    padding: 0.5rem 1rem 2rem 1rem;
    border-radius: 8px;
  }
  
  form {
    background: #fff;
  }
  

.formSection {
    padding: 20px 0;
}

.formSection.prosSection {
    padding: 8px 0 24px 0px;
    border-bottom: 1px solid #e6e6e6;
}

.formSection.consSection {
    padding-bottom: 40px;
}

  .inputWrapper {
    display: inline-block;
    color: white;
    padding: 0px;
    margin-right: 8px;
    margin-bottom: 16px;
    font-family: Arial;
    position: relative;
    top: 0px;
  }

  .textInputWrapper {
    width: calc(60% - 30px);
  }

  .textInput {
      width: 100%;
  }

  .dropdownWrapper  {
    width: calc(40% - 30px);
  }

  


  .dropdown {
    width: 100% !important;
  }

  
  .show {
    display: block !important;
  }

  .showInlineBlock {
    display: inline-block !important;
  }
  
  .hidden {
    display: none !important;
  }

  .errorMessage {
      background: #fff;
      padding: 14px;
      border-radius: 8px;
      border: 1px solid #c20000;
      font-size: 14px;
      line-height: 18px;
  }

  .errorMessageContainer {
      display: initial;
      margin-top: 16px;
  }

  .calculateError.show {
      margin-top: 16px;
  }
  
  .resultsSection {
    padding: 40px 0 0 0;
    display: block;
    border-top: 1px solid #e6e6e6;
    margin-top: 40px;
  }
  
.resultsStatsSection {
    margin-top: 40px;
}

.resultsStatsSection h2 {
    font-weight: 300;
}

.comparisonResult {
    margin-top: 2.5rem;
}

.comparisonResult h2 {
    font-weight: 300;
}


  .resultLabel {
    display: inline-block;
    margin-right: 8px;
  }
  
  .resultText {
    display: inline-block;
    font-weight: bold;
  }

  .winnerTitle {
    font-weight: 500;
}
  
  .sidebar {
    flex: 3 30%;
    background: #f5f5f5;
    padding: 1rem;
    border-radius: 0.5rem;
  }

  .css-zow5z4-MuiGrid-root {
      margin-top: 0 !important;
  }
  
  .css-1w4vsez-MuiFormControl-root {
    margin:0 !important;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 1.2rem 1rem !important;
}


  
 .inputWrapperAdd {
    display: block;
    color: white;
    padding: 0px;
    margin: 8px 0px 0px 0px;
    font-family: Arial;
    position: relative;
    top: 0px;
  }

  .iconButtons {
    position: relative;
    top: 18px;
  }

  .removeButton {
      width: 42px;
      margin-right: 0;
      top:18px;
  }

  .removeIconButton {
    border: 1px solid #b3b3b3 !important;
  }
  

  .iconAdd {
      top:0;
  }
  
 .sendButton{
    font-family: Arial;
  }

  .editButton {
    position: absolute;
    right: 0;
  }

  .compareButtonSection {
      margin-top: 24px;
  }

  header, footer {
    padding: 1rem 1.5rem;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-weight: 400;
  }

  footer {
      padding: 2rem 1.5rem;
      font-weight: 300;
  }

  .footerRight {
      float: right;
  }

  .pageContainer {
    max-width: 1152px;
    margin: 0 auto;
    position: relative;
}

.logoSection {
    max-width: 50%;
    display: inline-block;
}

.logoSection span {
    font-size: 0.1px;
}

.logoSection a {
    text-decoration: none;
    color: #094067;
    display: block;
    transition: all 0.3s;
    border-radius: 4px;
    width: 200px;
    height: 50px;
    background-size: contain;
}

.logoSection a:hover {
    background-color:rgba(104, 154, 219, 0.08);
}

footer .logoSection a {
    width: 160px;
    height: 40px;
    border-radius: 2px;
}

.chooseLanguage {
    max-width: 50%;
    float: right;
    margin-top: 6px;
}

.headerButton {
    color: #3E6D8E !important;
    border: 1px solid #3E6D8E;
}

.headerButton:hover {
    background: rgba(104, 154, 219, 0.08);
}

.headerButton:focus {
    background: rgba(104, 154, 219, 0.08);
    outline: 2px solid #3E6D8E;
}

.mdc-list-item a {
    display: block;
    padding: 0.75rem 0;
    text-decoration: none;
    color: #4d4d4d;
}

.mdc-list-item:hover {
    background-color: rgba(0,0,0,0.08);
}

.clearfix:after {
    content: "";
    display: table;
    clear: both;
  }



  .footerLinks {
      list-style: none;
      padding: 0;
      display: inline-block;
      margin-right: 3rem;
      padding-top: 0;
  }

  .footerLinks a {
      display: block;
      padding: 0.5rem 0;
      font-size: 0.9rem;
      color: #5f6c7b;
      transition: all 0.3s;
  }

  .footerLinks a:hover {
      text-decoration: none;
      color: #4d4d4d;
  }

  .menu-button-container {
    position: relative;
  }

  .mdc-menu .mdc-deprecated-list, .mdc-menu .mdc-list {
      right: 0 !important;
  }

  .mdc-menu-surface {
    right: 0 !important; 
  }

  #demo-menu {
    right: 0 !important; 
  }

  .mdc-menu-surface--open {
    display: inline-block;
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    position: absolute;
    left: initial !important;
    right: 0 !important;
}

.copyright {
    font-size: 0.8rem;
    text-align: left;
}

.adSlot {
    background: #fff;
}

.adContainer_inPage {
    margin-top: 2rem;
    border: 1px solid #e6e6e6;
}



.adContainer_sideBar {
    margin-top: 1rem;
    border: 1px solid #e6e6e6;
}





@media screen and (max-width: 375px) {
    .css-46bh2p-MuiCardContent-root {
        padding: 1.2rem 1rem !important;
    }
  }

  @media screen and (max-width: 768px) {
    

    h1 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1.15rem;
    }

    .mainContent {
        padding-top: 0;
        margin-top: 1rem !important;
    }

    header, footer {
        padding: 1.5rem 1rem;
    }
  
    footer {
        padding: 2rem 1rem;
    }

    .footerLinks {
        width: 100%;
        margin-right: 0;
    }

    .footerRight {
        float: left;
    }

    .copyright {
        text-align: left;
    }
  }

  